/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import Stake from "./Stake";
import UnStake from "./UnStake";

import useWalletNfts from "../../hooks/use-wallet-nfts";
import useNftStake from "../../hooks/use-nft-stake";
import Loader from "../loader/Loader";
import ProgressBar from "../loader/ProgressBar";
import toast from "react-hot-toast";

import Logo from "../assets/logo.png";
import WalletIcon from "../assets/wallet-icon.png";
import Discord from "../assets/discord.png";
import Twitter from "../assets/Twitter.png";
import Instagram from "../assets/instagram.png";

import { useEffect, useMemo, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import axios from "axios";
import { BACKEND_URL, MAX_COUNT, TOTAL_SUPPLY } from "../../constant/env";

import TwitterModal from "./TwitterModal";
import CliamListModal from "./CliamListModal";
import UserModal from "./UserModal";
const Home = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [unStackProductIds, setUnStackProduct] = useState<number[]>([]);
  const [stakedProductIds, setStakedProduct] = useState<number[]>([]);
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [showClaimListModal, setShowClaimListModal] = useState(false);
  const [showTweetModal, setShowTweetModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const {
    isLoading,
    stakedNfts,
    claimAmount,
    stakeNftList,
    unStakeNft,
    claimRewards,
    setStakedNfts,
    totalStakedNFT
  } = useNftStake();

  const { isLoadingWalletNfts, walletNfts, setWalletNfts } = useWalletNfts();

  const { publicKey, wallet } = useWallet();
  const base58 = useMemo(
    () =>
      publicKey === null || publicKey === void 0
        ? void 0
        : publicKey.toBase58(),
    [publicKey]
  );
  const content = useMemo(() => {
    if (!wallet || !base58) return null;
    return base58.slice(0, 4) + ".." + base58.slice(-4);
  }, [wallet, base58]);

  const [tweetId, setTweetId] = useState("");
  const [twitterName, setTwitterName] = useState("");
  const [tweetOptions, setTweetOptions] = useState([]);
  const [isConnecting, setConnecting] = useState(false);
  const [isClaimEnable, setClaimEnable] = useState(true);

  const twitterLogin = async (e: any) => {
    if (!publicKey) {
      toast.error("Connect wallet, please.");
      return;
    }
    try {
      const { data } = await axios.post(`${BACKEND_URL}/user`, {
        walletAddress: publicKey.toString(),
      });
      localStorage.setItem("wallet", publicKey ? publicKey.toString() : "");
      localStorage.setItem("token", JSON.stringify(data.data.token));
      const token = data.data.token;
      window.open(BACKEND_URL + "/oauth/twitter?token=" + token);
      setConnecting(true);
    } catch (error) {
      toast.error("Check network connection, please.");
    }
  };

  const disconnectMe = async (e: any) => {
    try {
      await axios.post(
        `${BACKEND_URL}/user/disconnect-social/${publicKey?.toString()}/twitter`
      );
      setTwitterName("");
    } catch (error) {}
  };

  const onClaim = async (e: any) => {
    if (!publicKey) {
      toast.error("Connect wallet, please.");
      return;
    }
    if (!twitterName) {
      toast.error("Connect twitter, please.");
      return;
    }

    setShowClaimListModal(true);
  };

  const onClaimToken = (index: Number) => {
    // setShowLoader(true);
    // claimRewards(index)
    //   .then(() => {
    //     stopLoader();
    //   })
    //   .catch((err) => {
    //     stopLoader();
    //   });
  };

  useEffect(() => {
    if (isConnecting && publicKey) {
    //   let intervalId = setInterval(() => {
    //     axios
    //       .get(BACKEND_URL + "/user/twitter/status/" + publicKey?.toString())
    //       .then((res) => {
    //         if (res.status === 200) {
    //           setTwitterName(res.data.data.name);
    //           setConnecting(false);
    //         }
    //       })
    //       .catch((err) => {
    //         setTwitterName("");
    //         setConnecting(false);
    //       });
    //   }, 3000);

    //   return () => {
    //     clearInterval(intervalId);
    //   };
    }
  }, [isConnecting, publicKey]); // handlers

  useEffect(() => {
    if (publicKey) {
    //   try {
    //     axios
    //       .get(`${BACKEND_URL}/user/tweetInfo/${publicKey?.toString()}`)
    //       .then((res) => {
    //         setTwitterName(res.data.data.name);
    //       })
    //       .catch((err) => {
    //         setTwitterName("");
    //         setTweetId("");
    //         setTweetOptions([]);
    //       });
    //   } catch (error) {}
    }
  }, [publicKey]);

  const handleUnstackProduct = (e: boolean, i: number) => {
    if (e) {
      if (unStackProductIds.length === MAX_COUNT) {
        toast.error('You can unstake 10 NFTs in maximum at once.')
        return;
      }
      setUnStackProduct([...unStackProductIds, i]);
    } else {
      setUnStackProduct(unStackProductIds.filter((item) => item !== i));
    }
  };

  const handleStakedProduct = (e: boolean, i: number) => {
    if (e) {
      if (unStackProductIds.length === MAX_COUNT) {
        toast.error('You can stake 10 NFTs in maximum at once.')
        return;
      }
      setStakedProduct([...stakedProductIds, i]);
    } else {
      setStakedProduct(stakedProductIds.filter((item) => item !== i));
    }
  };

  const handleStake = async () => {
    if (unStackProductIds.length <= 0) {
      toast.error("Select NFTs, please.");
      return;
    }
    setShowLoader(true);
    let stakeMode = 0;
    let staking_nfts = [];
    for (let i = 0; i < unStackProductIds.length; i++) {
      staking_nfts.push(walletNfts[unStackProductIds[i]]);
    }
    var filtered = walletNfts.filter(function (value, index, arr) {
      return unStackProductIds.indexOf(index) === -1;
    });

    const res = await stakeNftList(stakeMode, staking_nfts);
    if (res === 1) {
      setUnStackProduct([]);
      setStakedNfts(stakedNfts.concat(staking_nfts));
      setWalletNfts(filtered);
    }
  };

  const handleStakeAll = async () => {
    if (walletNfts.length <= 0) {
      toast.error("No NFTs.");
      return;
    }
    setShowLoader(true);
    let stakeMode = 0;
    const res = await stakeNftList(stakeMode, walletNfts.filter((_nft: any, index: number) => index < MAX_COUNT));
    if (res === 1) {
      setUnStackProduct([]);
      setStakedNfts(stakedNfts.concat(walletNfts.filter((_nft: any, index: number) => index < MAX_COUNT)));
      setWalletNfts([...walletNfts.filter((_nft: any, index: number) => index >= MAX_COUNT)]);
    }
  };

  const handleUnStake = async () => {
    if (stakedProductIds.length <= 0) {
      toast.error("Select NFTs, please");
      return;
    }
    setShowLoader(true);
    let unstaking_nfts = [];
    for (let i = 0; i < stakedProductIds.length; i++) {
      unstaking_nfts.push(stakedNfts[stakedProductIds[i]]);
    }

    var filtered = stakedNfts.filter(function (value, index, arr) {
      return stakedProductIds.indexOf(index) === -1;
    });

    const res = await unStakeNft(unstaking_nfts);
    if (res === 1) {
      setStakedProduct([]);
      setWalletNfts(walletNfts.concat(unstaking_nfts));
      setStakedNfts(filtered);
    }
  };

  const handleUnStakeAll = async () => {
    if (stakedNfts.length <= 0) {
      toast.error("No NFTs.");
      return;
    }
    setShowLoader(true);
    const res = await unStakeNft(stakedNfts.filter((_nft: any, index: number) => index < MAX_COUNT));
    if (res === 1) {
      setStakedProduct([]);
      setWalletNfts(walletNfts.concat(stakedNfts.filter((_nft: any, index: number) => index < MAX_COUNT)));
      setStakedNfts([...stakedNfts.filter((_nft: any, index: number) => index >= MAX_COUNT)]);
    }
  };

  const stopLoader = () => {
    setShowLoader(false);
    setLoadingMessage("");
  };

//   const onHandleTweet = (
//     isShow: boolean,
//     tweetId: string,
//     tweetOptions: []
//   ) => {
//     setTweetId(tweetId);
//     setTweetOptions(tweetOptions);
//     setShowTweetModal(isShow);
//   };

  return (
	<>
    <div className="text-white body-bg">
      {(showLoader || isLoading || isLoadingWalletNfts) && (
        <Loader text={loadingMessage} />
      )}
      <div className="px-4 lg:px-8 min-h-screen">
        {/* Main Navbar */}
        <nav className="sm:flex items-center justify-between py-5">
          <a href="/" className="sm:w-52 w-36">
            <img src={Logo} alt="main-logo" />
          </a>
		  <ul className="my-5 flex justify-center items-center md:items-start flex items-center justify-center">
			<li className="mx-5 text-white opacity-30 cursor-pointer" title="COMING SOON">
				Reward
			</li>
			<li className="mx-5 text-white opacity-30 cursor-pointer" title="COMING SOON">
				Explore Pixiu
			</li>
		  </ul>
          {/* {twitterName ? <div onClick={disconnectMe} className="mt-2 py-3 px-6 rounded-full flex items-center bg-gradient-to-r from-primary to-secondary cursor-pointer">
						{twitterName}
					</div> : <div onClick={twitterLogin} className="mt-2 py-3 px-6 rounded-full flex items-center bg-gradient-to-r from-primary to-secondary cursor-pointer">
						Connect to twitter
					</div>} */}
          <WalletMultiButton
            className="wallet-btn"
            style={{ background: "transparent" }}
          >
            {wallet ? (
              content
            ) : (
              <div className="mt-2 py-0 px-6 rounded-full flex items-center bg-gradient-to-r from-primary to-secondary cursor-pointer">
                <img src={WalletIcon} alt="wallet" className="mr-2 w-5" />
                Connect Wallet
              </div>
            )}
          </WalletMultiButton>
        </nav>
        <h2
          style={{
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: "lightblue",
            width: "100%",
            borderRadius: "10px",
          }}>NFT's Staked: {totalStakedNFT} / {TOTAL_SUPPLY} </h2>
	      <ProgressBar bgcolor="#ef6c00" completed={(totalStakedNFT)} /> 

        {/* Bonus Claim Bar  */}
        <div className="sm:flex items-center justify-between mt-10 bg-LightPurple rounded-2xl sm:py-8 py-5 md:px-11 sm:px-8 px-5">
          <h1 className="text-2xl">Stake and earn rewards (1 $SSWIPE / Day)</h1>
          <div className="sm:flex items-center">
            <h1 className="my-5 sm:my-0 text-2xl">{claimAmount} $SSWIPE</h1>
            <button
              className="sm:ml-5 bg-gradient-to-r from-primary to-secondary py-3 px-8 rounded-2xl"
              title="COMING SOON"
              // onClick={onClaim}
            >
              Claim
            </button>
          </div>
        </div>
        {/* Staking Main  */}
        <div className="lg:flex justify-between mt-10">
          {/* Left  */}
          <div className="px-3 pt-3 rounded-borderContainer basis-half bg-gradient-to-r from-Secprimary to-Secsecondary mb-4 lg:mb-0">
            <div className="flex">
			  <div className="flex items-center justify-start w-2/5">
				<p>NFTs in wallet: {walletNfts.length}</p>
			  </div>
              <div className="flex items-centern w-3/5  justify-end">
                <button
                  className="relative gradient-border py-btny px-btnx text-center rounded-2xl"
                  onClick={() => {
                    handleStake().then(() => {
                      stopLoader();
                    });
                  }}
                >
                  <span className="text-transparent bg-clip-text bg-gradient-to-r from-secondary to-primary">
                    Stake
                  </span>
                </button>
                <button
                  className="ml-5 bg-gradient-to-r from-primary to-secondary py-3 px-btnx rounded-2xl"
                  onClick={() => {
                    handleStakeAll().then(() => {
                      stopLoader();
                    });
                  }}
                >
                  Stake All
                </button>
              </div>
            </div>
            {/* Staking Container  */}
            <div className="flex gap-3 flex-wrap mt-3 panel">
              {walletNfts.length > 0 ? (
                walletNfts.map((nft, idx) => {
                  return (
                    <Stake
                      key={idx}
                      selected={unStackProductIds.indexOf(idx) !== -1}
                      nft={nft}
                      index={idx}
                      image={nft.image}
                      name={nft.name}
                      handleOrderCollect={handleUnstackProduct}
                    />
                  );
                })
              ) : (
                <div
                  style={{
                    padding: "12em 1em",
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    // backgroundColor: "lightblue",
                    width: "100%",
                    borderRadius: "10px",
                    margin: "1em 1em",
                  }}
                >
                  {!(showLoader || isLoading || isLoadingWalletNfts) &&
                    "No NFTs"}
                </div>
              )}
            </div>
          </div>
          {/* Right  */}
          <div className="px-3 pt-3 rounded-borderContainer basis-half bg-gradient-to-r from-Secprimary to-Secsecondary">
		  <div className="flex">
			  <div className="flex items-center justify-start w-2/5">
				<p>Staked NFTs: {stakedNfts.length}</p>
			  </div>
              <div className="flex items-centern w-3/5 justify-end">
                <button
                  className="relative gradient-border py-btny px-btnx text-center rounded-2xl"
                  onClick={() => {
                    handleUnStake().then(() => {
                      stopLoader();
                    });
                  }}
                >
                  <span className="text-transparent bg-clip-text bg-gradient-to-r from-secondary to-primary">
                    UnStake
                  </span>
                </button>
                <button
                  className="ml-5 bg-gradient-to-r from-primary to-secondary py-3 px-btnx rounded-2xl"
                  onClick={() => {
                    handleUnStakeAll().then(() => {
                      stopLoader();
                    });
                  }}
                >
                  UnStake All
                </button>
              </div>
            </div>

            <div className="flex gap-3 flex-wrap mt-3 panel">
              {stakedNfts.length > 0 ? (
                stakedNfts.map((nft, idx) => {
                  return (
                    <UnStake
                      key={idx}
                      selected={stakedProductIds.indexOf(idx) !== -1}
                      nft={nft}
                      index={idx}
                      image={nft.image}
                      name={nft.name}
                      handleOrderCollect={handleStakedProduct}
                    />
                  );
                })
              ) : (
                <div
                  style={{
                    padding: "12em 1em",
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    // backgroundColor: "lightblue",
                    width: "100%",
                    borderRadius: "10px",
                    margin: "1em 1em",
                  }}
                >
                  {!(showLoader || isLoading || isLoadingWalletNfts) &&
                    "No NFTs"}
                </div>
              )}
            </div>
          </div>
        </div>
        <footer
          className="m-auto md:pt-footer pt-footersm md:pb-footer pb-bfooter sticky"
          style={{ top: "90vh" }}
        >
          <div className="flex  items-center justify-between">
            <div className="flex-1">
              <a href="/" className="sm:w-52 w-36">
                <img src={Logo} alt="main-logo" />
              </a>
                <span className="text-base mt-6 block">
                  {" "}
                  Solswipe is a digital collection and global community of
                  creators, developers, and innovators. We are the change
                  makers.
                </span>
            </div>
            <div className="text-center flex-1">
              <h4 className="text-base text-center md:text-start">
                Follow us
              </h4>
              <ul className="my-5 flex justify-center items-center md:items-start flex items-center justify-center">
                <li className="mx-5">
                  <a href="https://discord.gg/tVzdjzFX4V" target="_blank" rel="noreferrer">
                    <img src={Discord} alt="Telegram" className="w-8" />
                  </a>
                </li>
                <li className="mx-5">
                  <a href=" https://twitter.com/solswipecard" target="_blank" rel="noreferrer">
                    <img src={Twitter} alt="Twitter" className="w-8" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="text-center flex-1">
              <p className="text-base text-center md:text-start">
			  Copyright @ 2022 , Made by Solswipe
              </p>
            </div>
          </div>
        </footer>

        {/* MODAL  */}

        {/* {showClaimListModal && (
          <CliamListModal
            isClaimEnable={isClaimEnable}
            handleClose={setShowClaimListModal}
            handleTweet={onHandleTweet}
            handleClaimToken={onClaimToken}
            handleUser={setShowUserModal}
          />
        )} */}

        {/* {showTweetModal && (
          <TwitterModal
            tweetId={tweetId}
            walletAddress={publicKey?.toString()}
            options={tweetOptions}
            handleClose={setShowTweetModal}
            handleClaimEnable={setClaimEnable}
          />
        )}

        {showUserModal && (
          <UserModal
            tweetId={tweetId}
            walletAddress={publicKey?.toString()}
            handleClose={setShowUserModal}
            handleClaimEnable={setClaimEnable}
          />
        )} */}
      </div>
    </div>
	</>
  );
};

export default Home;
