export const NEXT_PUBLIC_SOLANA_NETWORK = 'mainnet-beta';
export const NEXT_PUBLIC_STAKE_CONTRACT_ID = '9cwZnH19cQgnvSM5v297qpAhH5JXgqg2qcU9cdgumLCk';
export const BACKEND_URL = 'https://solsw.herokuapp.com';
export const RPC_URL = 'https://convincing-wild-borough.solana-mainnet.quiknode.pro/387e6bc5581d8d3828e3ef7e658e6f44cc97a1ba/';
export const TOTAL_SUPPLY = 6666;
export const MAX_COUNT = 10;

// export const BACKEND_URL = 'http://localhost:5000';
export const LIKE = 'Like';
export const COMMENT = 'Comment';
export const RETWEET = 'Retweet';
export const QUOTE = 'Quoted Tweet';

export const USER_POOL_SEED = "user-pool";
